
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productservice from "@/services/product-service";
import ProjectService from "@/services/project-service";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import Moment from "moment";
import ExportOptions from "@/components/export-options.vue"
@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    Loader,
    FilterComponent,
    ExportOptions
  },
})
export default class StockList extends Vue {
  loading = false;
  public obj: any = {};
  public currentItem: any = null;
  public currentIndex: number = -1;
  public title: string = "";
  // public rows = 100;

  public currentPage = 1
  public items: any = []
  public master_items: any = [];
  public transfer_items: any = [];


  public ddl_item: any = []
  public ddl_item_code: any = {};

  public from_ddl_project: any = []
  from_ddl_project_code: any = {};

  public to_ddl_project: any = []
  to_ddl_project_code: any = {};

  public widths = [90, 90, 90, 30, 90, 70]
  public columns = [
    {
      label: 'SKU',
      field: 'sku',
    },
    {
      label: 'Title',
      field: 'title',
    },
    {
      label: 'Ware House',
      field: 'project_tile',
    },
    {
      label: 'Qty',
      field: 'qty',
    },
    {
      label: 'Owner',
      field: 'owner',
    },
    {
      label: 'Created At',
      field: 'created_at',
      sortable: true,
      format: (value: any) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString();
        return `${formattedDate}  `;
      },
    },
  ];
  get rows() {
    return this.master_items.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.master_items.slice(start, end);
  }
  refreshList() {
    this.master_items = [];
    this.to_ddl_project = [];
    this.to_ddl_project_code = {}
    this.currentItem = null;
    this.currentIndex = -1;
  }


  to_search_project(title: any) {
    if (title.length > 4) {
      ProjectService.getByTitle(title)
        .then((response) => {
          this.to_ddl_project = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) { this.to_ddl_project = []; }
  }

  to_get_project(data: any) {
    this.to_ddl_project_code = data;
    if (this.to_ddl_project_code) { this.to_ddl_project = []; }
  }
  search_stock() {
    this.loading = true;
    // productservice.get_item_stock_project(this.to_ddl_project_code['id'])
    productservice.get_item_stock_project(this.to_ddl_project_code.title, "Inventory")
      .then((response) => {
        this.master_items = response.data.result;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  }


  mounted() {
    // this.get_items();
    this.obj = {};
    this.retrieve()
  }
  retrieve() {
    this.loading = true;
    ProjectService.get_list()
      .then((response) => {
        this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  advance_filter_fields = [
    { type: "text", label: "SKU", name: "sku", value: "", param_name: "sku", filter_type: "advance", },
    { type: "text", label: "Title", name: "title", value: "", param_name: "title", filter_type: "advance", },
    { type: "text", label: "Quantity", name: "quantity", value: "", param_name: "qty", filter_type: "advance", },
    // { type: "text", label: "Ware House", name: "warehouse", value: "", param_name: "project_title", filter_type: "advance", },
    { type: "text", label: "Owner", name: "owner", value: "", param_name: "owner", filter_type: "advance", },
    { type: "text", label: "Created", name: "created", value: "", param_name: "created_at", filter_type: "advance", },
    { type: "date", label: "Created Date Range", min_param_name: "start_created_at", max_param_name: "end_created_at", start: "", end: "", filter_type: "advance", range: [], },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value;
          }
        }
        if (filter.type === "date") {
          if (filter.min_param_name && filter.range) {
            const date0 = new Date(filter.range[0]);
            const formattedDate0 = Moment(date0).format("YYYY-MM-DD");
            advanceFilteredObj[filter.min_param_name] =
              formattedDate0 === "Invalid date" ? "" : formattedDate0;
          }
          if (filter.max_param_name && filter.range) {
            const date1 = new Date(filter.range[1]);
            const formattedDate1 = Moment(date1).format("YYYY-MM-DD");
            advanceFilteredObj[filter.max_param_name] =
              formattedDate1 === "Invalid date" ? "" : formattedDate1;
          }
        }
      });
      this.loading = true;
      FilterService.get_item_stock(advanceFilteredObj, this.to_ddl_project_code.title).then((data) => {
        if (data.data.data !== undefined && data.data.data !== null) {
          this.master_items = data.data.data;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    this.refreshList();
    // for (let field of this.filter_fields) {
    //   field.value = "";
    // }
    this.retrieve();
  }
  refreshAdvanceFields() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "text") {
        field.value = "";
      }
      this.to_ddl_project_code.title;
    }
  }
  calculateGrandTotal() {
    if (this.master_items.length === 0) {
      return 0; // No items, so the total is zero.
    }

    // Use reduce to sum up the 'qty' property of each item in master_items.
    return this.master_items.reduce((total: any, item: any) => total + item.qty, 0);
  }
}
